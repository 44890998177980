<template>
    <UForm :schema="schema" :state="state" @submit="onSubmit" class="grid gap-4">
        <UFormGroup :ui="{ label: { base: (ui as any).label.base } }" name="email" label="Your email">
            <UInput placeholder="email@example.com" v-model="state.email" />
        </UFormGroup>

        <button
            type="submit"
            :class="buttonClasses"
            class="flex items-center font-medium justify-center gap-2 whitespace-nowrap disabled:opacity-50 dark:disabled:opacity-75 disabled:cursor-default rounded-lg px-4 hover:bg-zinc-900 dark:bg-white dark:hover:bg-zinc-100 text-white dark:text-zinc-800 dark:shadow-none group-[]/button:border-r group-[]/button:last:border-r-0 w-full !text-base !h-12"
        >
            <span v-if="showSuccess">Thanks! You're on our list!</span>
            <span v-else-if="errorMessage">{{ errorMessage }}</span>
            <span v-else>Get early access</span>
        </button>
    </UForm>
</template>

<script setup lang="ts">
import * as v from 'valibot';
import type { FormSubmitEvent } from '#ui/types';
import ApiError from '~/api/models/ApiError';

const props = defineProps({
    ui: Object,
});

const { ui } = toRefs(props);

const schema = v.object({
    email: v.pipe(v.string(), v.email('Please enter a valid email address')),
});

type Schema = v.InferOutput<typeof schema>;

const state = reactive({
    email: '',
});

const api = useApi();

const showSuccess = ref(false);
const errorMessage = ref<string | null>(null);
async function onSubmit(event: FormSubmitEvent<Schema>) {
    showSuccess.value = false;
    errorMessage.value = null;
    try {
        await api.v1.auth.earlyAccess(event.data);
        showSuccess.value = true;
        state.email = '';
    } catch (error) {
        if (error instanceof ApiError) {
            errorMessage.value = error.message;
        } else {
            errorMessage.value = 'An unexpected error occurred. Please try again later.';
        }
    }
}

const buttonClasses = computed(() => ({
    'bg-zinc-800 group-[]/button:dark:border-zinc-900/25 shadow-[inset_0px_1px_theme(colors.zinc.900),inset_0px_2px_theme(colors.white/.15)] group-[]/button:border-black':
        !showSuccess.value,
    'bg-green-500 group-[]/button:dark:border-green-600/25 shadow-[inset_0px_1px_theme(colors.green.500),inset_0px_2px_theme(colors.white/.15)] group-[]/button:border-black':
        showSuccess.value,
}));
</script>
