<template>
    <div
        class="shadow-2xl no-mobile-zoom shadow-black rounded-[calc(16px+12px)] mx-auto aspect-[5/9] w-56 border-[12px] border-black bg-stone-900"
    >
        <div class="flex gap-4 px-3 py-2 rounded-2xl items-center flex-col bg-stone-900">
            <div class="flex items-center pt-5 font-bold">
                <div class="m-auto text-3xl text-amber-400">
                    {{ formattedTimeLeft }}
                </div>
            </div>
            <div class="flex flex-col w-full gap-6">
                <ControlGroup>
                    <template #info>
                        <div class="flex flex-col gap-0.5">
                            <span class="text-xs leading-none text-amber-400">Game time</span>
                            <span class="text-xl leading-none font-bold text-white">{{ formattedTimeLeft }}</span>
                            <span class="text-xs leading-none text-white">10 min.</span>
                        </div>
                    </template>
                    <template #action>
                        <ControlButton
                            @click="scoreboardStore.handlePlayButtonPress"
                            color="amber"
                            :icon="isPaused ? 'i-ri-play-fill' : 'i-ri-pause-fill'"
                        />
                    </template>
                </ControlGroup>
                <ControlGroup>
                    <template #info>
                        <div class="flex flex-col gap-0.5">
                            <span class="text-xs leading-none text-red-400">Shot clock</span>
                            <span class="text-xl leading-none font-bold text-white">{{ formattedShotClock }}</span>
                            <span class="text-xs leading-none text-white">60 sec.</span>
                        </div>
                    </template>
                    <template #action>
                        <ControlButton @click="scoreboardStore.resetShotClock" color="red" icon="i-ri-restart-line" />
                    </template>
                </ControlGroup>
                <ControlGroup>
                    <template #info>
                        <div class="flex flex-col">
                            <span class="text-xs leading-none text-blue-400">Home score</span>
                            <span class="text-xl leading-none font-bold text-white">{{ scoreA }}</span>
                        </div>
                    </template>
                    <template #action>
                        <div class="flex gap-2 items-center">
                            <ControlButton
                                v-if="scoreA > 0"
                                small
                                @click="scoreboardStore.decrementScoreA"
                                color="blue"
                                icon="i-ri-subtract-fill"
                            />
                            <ControlButton @click="handleScoreAIncrease" color="blue" icon="i-ri-add-fill" />
                        </div>
                    </template>
                </ControlGroup>
                <ControlGroup>
                    <template #info>
                        <div class="flex flex-col">
                            <span class="text-xs leading-none text-green-400">Away score</span>
                            <span class="text-xl leading-none font-bold text-white">{{ scoreB }}</span>
                        </div>
                    </template>
                    <template #action>
                        <div class="flex gap-2 items-center">
                            <ControlButton
                                v-if="scoreB > 0"
                                small
                                @click="scoreboardStore.decrementScoreB"
                                color="green"
                                icon="i-ri-subtract-fill"
                            />
                            <ControlButton @click="handleScoreBIncrease" color="green" icon="i-ri-add-fill" />
                        </div>
                    </template>
                </ControlGroup>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useScoreboardStore } from '@/stores/scoreboard.ts';
const scoreboardStore = useScoreboardStore();
const { formattedTimeLeft, formattedShotClock, isPaused, scoreB, scoreA } = storeToRefs(scoreboardStore);

const handleScoreAIncrease = () => {
    scoreboardStore.incrementScoreA();
    scoreboardStore.hasInteracted = true;
};

const handleScoreBIncrease = () => {
    scoreboardStore.incrementScoreB();
    scoreboardStore.hasInteracted = true;
};
</script>
