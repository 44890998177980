<script lang="ts">
const toggleGroupItemClasses =
    'hover:bg-yellow-100 text-yellow-900 focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-offset-2 data-[state=on]:bg-stone-900 data-[state=on]:text-amber-100 flex w-full h-14 flex-col px-2 py-10 items-center justify-center bg-white text-base leading-4 rounded-md focus:z-10 focus:shadow-[0_0_0_2px] focus:shadow-black focus:outline-none'
</script>
<script setup lang="ts">
import { ToggleGroupItem, ToggleGroupRoot } from 'radix-vue';

interface Item {
    value: string;
    label: string;
    description?: string;
    icon?: string;
}

const props = defineProps({
    items: {
        type: Array as PropType<Item[]>,
        required: true,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
});

const [modelValue] = defineModel<string | string[] | undefined>({ required: true });

const type = computed(() => (props.multiple ? 'multiple' : 'single'));


</script>

<template>
    <div>
        <ToggleGroupRoot v-model="modelValue" :type="type" class="flex gap-4">
            <ToggleGroupItem
                v-for="item in items"
                :value="item.value"
                :aria-label="item.label"
                :class="toggleGroupItemClasses"
            >
                <UIcon v-if="item.icon" class="w-5 h-5 shrink-0" :name="item.icon" />

                <span class="text-xs">{{ item.label }}</span>
            </ToggleGroupItem>
        </ToggleGroupRoot>
    </div>
</template>
