<template>
    <button
        :class="[size, ringColour, classStyles]"
        :style="boxShadow"
        class="relative no-mobile-zoom items-center justify-center flex-shrink-0 text-center ring-1 ring-opacity-50 rounded-full ring-inset"
    >
        <div
            class="absolute ring-1 group-hover:scale-100 ring-stone-800 opacity-75 rounded-full w-full h-full -translate-y-1/2 top-1/2"
        ></div>
        <div
            :class="ringColour"
            class="absolute ring-inset ring-2 opacity-75 rounded-full w-full h-full -translate-y-1/2 top-1/2"
        ></div>
        <div
            :class="ringLightColour"
            class="absolute ring-inset ring-1 opacity-75 rounded-full w-full h-full -translate-y-1/2 top-1/2"
        ></div>
        <UIcon
            :class="textColour"
            class="absolute -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2 h-4 w-4 m-auto"
            :name="icon"
        />
    </button>
</template>

<script setup>
const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
    small: {
        type: Boolean,
        default: false,
    },
    color: {
        type: String,
        default: 'amber',
    },
});

const ringColour = computed(() => {
    if (props.color === 'amber') {
        return 'ring-amber-500';
    }
    if (props.color === 'blue') {
        return 'ring-blue-500';
    }
    if (props.color === 'green') {
        return 'ring-green-500';
    }
    if (props.color === 'red') {
        return 'ring-red-500';
    }
    return 'ring-amber-500';
});

const textColour = computed(() => {
    if (props.color === 'amber') {
        return 'text-amber-800';
    }
    if (props.color === 'blue') {
        return 'text-blue-800';
    }
    if (props.color === 'green') {
        return 'text-green-800';
    }
    if (props.color === 'red') {
        return 'text-red-900';
    }
    return 'text-amber-800';
});

const ringLightColour = computed(() => {
    if (props.color === 'amber') {
        return 'ring-amber-400';
    }
    if (props.color === 'blue') {
        return 'ring-blue-400';
    }
    if (props.color === 'green') {
        return 'ring-green-400';
    }
    if (props.color === 'red') {
        return 'ring-red-400';
    }
    return 'ring-amber-400';
});

const size = computed(() => {
    if (props.small) {
        return 'w-5 h-5';
    }
    return 'w-10 h-10';
});

const boxShadow = computed(() => {
    if (props.color === 'amber') {
        return {
            boxShadow: '1px 1px 1.5px #d97706, -1px -1px 1.5px #fff',
        };
    }
    if (props.color === 'blue') {
        return {
            boxShadow: '1px 1px 1.5px #2563eb, -1px -1px 1.5px #fff',
        };
    }
    if (props.color === 'green') {
        return {
            boxShadow: '1px 1px 1.5px #16a34a, -1px -1px 1.5px #fff',
        };
    }
    if (props.color === 'red') {
        return {
            boxShadow: '1px 1px 1.5px #dc2626, -1px -1px 1.5px #fff',
        };
    }
});

const classStyles = computed(() => {
    if (props.color === 'amber') {
        return 'bg-gradient-to-br from-amber-400 to-amber-600 shadow-md relative transition-transform text-white font-bold text-2xl';
    }
    if (props.color === 'blue') {
        return 'bg-gradient-to-br from-blue-400 to-blue-600 shadow-md relative transition-transform text-white font-bold text-2xl';
    }
    if (props.color === 'green') {
        return 'bg-gradient-to-br from-green-400 to-green-600 shadow-md relative transition-transform text-white font-bold text-2xl';
    }
    if (props.color === 'red') {
        return 'bg-gradient-to-br from-red-400 to-red-600 shadow-md relative transition-transform text-white font-bold text-2xl';
    }
});
</script>
