<template>
    <div
        class="w-full sm:p-2 flex flex-col gap-2 rounded-3xl sm:border border-zinc-200 dark:border-zinc-700 bg-zinc-100 dark:bg-zinc-900"
    >
        <div
            class="relative -mx-4 sm:mx-0 overflow-hidden bg-stone-900 px-6 pt-16 shadow-2xl sm:rounded-2xl sm:px-16 md:pt-24 lg:flex pb-20 lg:gap-x-20 lg:px-24 lg:pt-0"
        >
            <svg
                viewBox="0 0 1024 1024"
                class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                aria-hidden="true"
            >
                <circle
                    cx="512"
                    cy="512"
                    r="512"
                    fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                    fill-opacity="0.7"
                />
                <defs>
                    <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                        <stop stop-color="#FFC452" />
                        <stop offset="1" stop-color="#d19200" />
                    </radialGradient>
                </defs>
            </svg>
            <div class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-20 lg:text-left">
                <h2 class="text-3xl font-bold text-balance tracking-tight text-amber-300 sm:text-4xl">
                    Get early access to our scoreboard
                </h2>
                <p class="mt-6 text-lg leading-6 text-amber-100">
                    We've got limited scoreboards available for early access. Sign up now to get yours.
                </p>
                <div class="mt-10 w-full flex items-center justify-center lg:justify-start gap-2">
                    <div class="flex w-64 flex-col gap-4">
                        <UserEarlyAccessForm :ui="{ label: { base: 'text-white' } }" />
                    </div>
                </div>
            </div>
            <div class="relative h-full">
                <NuxtImg
                    src="/images/field.jpg"
                    width="1824"
                    height="1080"
                    class="absolute blur left-0 top-0 w-[60rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                />
            </div>
        </div>
    </div>
</template>

<script setup></script>
